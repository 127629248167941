import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { TYPE_TERRAFORM_WORKSPACE } from 'common/utils/cloud-resource-types';
import {
  ACTION_LIST,
  PREFIX_TERRAFORM_WORKSPACES,
} from 'authz/utils/permission-types/index';

export default class CloudServicesTerraformWorkspacesListRoute extends Route {
  @service api;
  @service permissions;
  @service userContext;

  async model({ resource_id }) {
    const { resources } =
      await this.api.resourceManager.resources.resourceServiceListAccessibleResources(
        null,
        resource_id,
        TYPE_TERRAFORM_WORKSPACE,
        this.permissions.generate(PREFIX_TERRAFORM_WORKSPACES, ACTION_LIST)
      );
    return {
      workspace: resources[0],
      organizationId: this.userContext.organization.id,
      projectId: this.userContext.project.id,
    };
  }
}
