import EmberRouter from '@embroider/router';
import config from 'hcp/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('accept-invitation', {
    path: '/accept-invitation/:invitation_token',
  });
  this.route('sign-in');
  this.route('sign-up');
  this.route('terms-of-service');
  this.route('additional-terms-of-service');
  this.route('sla');
  this.route('shared-responsibility-model');
  this.route('callback', { path: '/login/callback/' });
  this.route('verify', { path: '/verify/:user_verification_token' });

  this.route('license-utilization', function () {
    this.route('reports', function () {
      this.route('create');
    });
  });

  this.mount('vagrant', {
    as: 'vagrant-public',
    resetNamespace: true,
    path: '/vagrant',
  });

  // everything contained in here is authenticated
  this.route('cloud', { path: '/' }, function () {
    this.route('services', function () {
      // resetNamespace here means `cloud` won't be used in the name
      // of the nested routes. So they will be `consul`, etc. instead of `cloud.services.consul`
      this.mount('consul', {
        resetNamespace: true,
        path: '/consul',
      });
      this.route('terraform', { resetNamespace: true }, function () {
        this.route('index', { path: '' });
        this.route('workspaces', function () {
          this.route('list', { path: '' });
          this.route('detail', { path: '/:resource_id' });
        });
      });

      this.mount('vault', {
        resetNamespace: true,
        path: '/vault',
      });

      this.mount('packer', {
        as: 'packer',
        resetNamespace: true,
        path: '/packer',
      });

      this.mount('waypoint', {
        as: 'waypoint',
        resetNamespace: true,
        path: '/waypoint',
      });

      this.mount('boundary', {
        as: 'boundary',
        resetNamespace: true,
        path: '/boundary',
      });

      this.mount('vagrant', {
        as: 'vagrant',
        resetNamespace: true,
        path: '/vagrant',
      });

      this.mount('secrets', {
        as: 'secrets',
        resetNamespace: true,
        path: '/secrets',
      });

      this.route('vault-radar');
    });

    this.mount('billing', {
      as: 'billing',
      resetNamespace: true,
      path: '/billing',
    });
    this.route('access-control', { path: '/access' }, function () {
      this.mount('role-assignments', {
        as: 'role-assignments',
        path: '/role-assignments',
      });
      this.route('invites', function () {
        this.route('list', { path: '' });
      });
      this.route('users', function () {
        this.route('list', { path: '' });
        this.route('detail', { path: '/:user_id' }, function () {
          this.route('edit-assignment');
        });
        this.route('invite');
      });
      this.route('service-principals', function () {
        this.route('create');
        this.route('list', { path: '' });
        this.route('detail', { path: '/:principal_id' }, function () {
          this.route('edit');
          this.route('keys');
          this.route('workload-identity-providers');
        });
      });
      this.route('groups', function () {
        this.route('add', { path: 'add-groups' });
        this.route('create');
        this.route('detail', { path: '/:resource_name' }, function () {
          this.route('edit');
          this.route('members', function () {
            this.route('add');
            this.route('edit');
          });
        });
        this.route('list', { path: '' });
      });
    });

    this.route('orgs', {}, function () {
      this.route('create', function () {
        this.route('index', { path: '' });
      });
      this.route('list', { path: '' });
      this.route('detail', { path: '/:org_id' }, function () {
        this.route('redirect');
        this.route('hcp-with-terraform');
        this.mount('monitoring');
        this.route('settings', function () {
          this.route('index', { path: '' }, function () {
            this.route('rename');
          });
          this.route('sso', function () {
            this.route('select-method');
            this.route('oidc', function () {
              this.route('configure');
            });
            this.route('saml', function () {
              this.route('configure');
            });
          });
          this.route('scim');
        });

        this.route('projects', {}, function () {
          this.route('create');
          this.route('list', { path: '' });
          this.route('detail', { path: '/:project_id' }, function () {
            this.route('index', { path: '' });
            this.route('edit');

            this.mount('monitoring');
            this.route('settings', function () {
              this.route('general');
              this.mount('webhooks', {
                as: 'webhooks',
                path: '/webhooks',
              });
            });

            this.route('active-resources');
            this.route('hvns', {}, function () {
              this.route('create');
              this.route('list', { path: '' });
              this.route('detail', { path: '/:hvn_id' }, function () {
                this.route('index', { path: '/' });
                this.route('hvn-routes', { path: '/route-table' }, function () {
                  this.route('create');
                });
                this.route('peerings', {}, function () {
                  this.route('create');
                  this.route('list', { path: '' });
                  this.route('detail', { path: '/:peering_id' }, function () {
                    this.route('index', { path: '/' });
                    this.route('configuration-instructions', {}, function () {
                      this.route('index', { path: '/' });
                    });
                    this.route('hvn-routes', function () {
                      this.route('create');
                    });
                  });
                });
                this.route('transit-gateway-attachments', {}, function () {
                  this.route('create');
                  this.route('list', { path: '' });
                  this.route('detail', { path: '/:tgw_id' }, function () {
                    this.route('index', { path: '/' });
                    this.route('configuration-instructions', {}, function () {
                      this.route('index', { path: '/' });
                    });
                    this.route('hvn-routes', function () {
                      this.route('create');
                    });
                  });
                });
                this.route('connected-clusters', {}, function () {});
              });
            });
          });
        });
      });
    });
    this.route('404', { path: '/*path' });
    this.route('account-settings', {}, function () {
      this.route('basic-info', { path: '' });
      this.route('security');
      this.route('session-manager');
    });
  });
  this.route('service-agreements');
  this.route('sign-out');
  this.route('tfc-migration-notification');
});
