import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class VaultRadarTenantService extends Service {
  @service api;
  @service userContext;
  @service abilities;

  @tracked _tenantData = {};
  @tracked _error = null;
  @tracked _loading = false;

  get tenantData() {
    return this._tenantData;
  }

  get error() {
    return this._error;
  }

  get loading() {
    return this._loading;
  }

  async fetch() {
    const { organization, project } = this.userContext;

    // Return data if already fetched, loading, or cannot view vault radar
    if (
      this._loading ||
      this._tenantData?.type ||
      this.abilities.cannot('view vault-radar')
    ) {
      return this._tenantData;
    }

    try {
      this._loading = true;
      const tenantResponse = await this.api.vaultRadar.tenant.getTenant(
        project.id,
        {
          location: {
            organizationId: organization.id,
          },
        }
      );

      this._tenantData = tenantResponse;
      return this._tenantData;
    } catch (error) {
      this._error = error;
      // Do nothing if failed
      return this._tenantData;
    } finally {
      this._loading = false;
    }
  }
}
